@keyframes jump {
  0% {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
  40% {
    transform: translate3d(0, 15%, 0) scale3d(0.8, 1.2, 1);
  }
  100% {
    transform: translate3d(0, 50%, 0) scale3d(1.2, 0.8, 1);
  }
}

.jump {
  transform-origin: 50% 50%;
  animation: jump .5s linear alternate infinite;
}

body {
  background-color: #000000;
  color: white;
}

.frase-motivadora {
  font-family: MarvinVisions;
  color: #5296FF;
}

.body-text,
span {
  font-size: 1rem;
  color: #ffffff;
}

.navbar-collapse {
  flex-grow: 0;
}

.navbar-toggler {
  border: none;
}

.nav-link {
  cursor: pointer;
  color: #ffffff;
}

.btn-link {
  text-decoration: none;
}

.btn-custom {
  font-weight: bold;
  font-size: 1.2rem;
}

.pregunta {
  color: #000000;
}

.respuesta {
  color: #000000;
}

.preguntas .card {
  background-color: #ffffff;
  color: #000000;
  text-align: left;
}

.preguntas .card-header {
  background-color: #ffffff;
  color: #000000;
  text-align: left;
}

.preguntas .card-body {
  background-color: #ffffff;
  color: #000000;
  text-align: left;
}

.preguntas .btn-custom {
  color: #000000;
  text-align: left;
}

.preguntas .btn-link {
  color: #000000;
  text-align: left;
}

.preguntas .btn-block {
  width: 100%;
}

.navbar {
  position: fixed;
  width: 100%;
  z-index: 1000;
}

header {
  padding-top: 10rem;
}

.imgCarrousel {
  width: 80% !important;
}

.titulos-fondo {
  font-family: MarvinVisions;
}

.contenedor-agenda-llamada {
  background-size: cover;
  background-image: url("../../assets/imagenes_videos/BannerIsma.png");
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-button::after {
  content: "\f04b";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 36px;
  color: rgba(0, 0, 0, 0.8);
}

@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.btn-success {
  font-family: 'MarvinVisions';
  font-weight: bold;
  padding: 10px 30px;
  animation: moveUpDown 2s infinite;
}

.call-to-action-section .btn-success {
  animation: moveUpDown 2s infinite;
}

.btn-link {
  color: #000000;
  text-decoration: none;
  font-weight: bold;
}

.btn-link:hover {
  color: #000000;
  text-decoration: none;
}

.btn-link[disabled] {
  color: gray;
  text-decoration: line-through;
  cursor: not-allowed;
  font-weight: bold;
  text-decoration-color: #5296FF;
}

.btn-link[disabled]:hover {
  color: gray;
  text-decoration: line-through;
  text-decoration-color: #5296FF;
}

.card-text {
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
}

@media (max-width: 360px) {
  .card-text {
    font-size: 0.75rem;
  }
}

.benefitsCard {
  background-color: #000000;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.benefitsCard .card-body {
  background-color: transparent;
  padding: 10px 0;
}

.benefitsCard .card-text {
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 10px;
}

.benefitsCard .card-img-top {
  max-width: 80px;
  max-height: 80px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
}

.benefitsSection .row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.benefitsSection .col-6 {
  padding: 15px;
  display: flex;
  justify-content: center;
}

.benefitsSection {
  display: flex;
  justify-content: center;
}

.preguntas .card-header,
.preguntas .card-body,
.preguntas .btn-custom,
.preguntas .btn-link {
    text-align: left;
}

.benefitsCard .card-text {
  text-align: left;
}

.modal-header {
  padding-top: 50px;
  border-bottom: none;
  justify-content: center;
}

.modal-title {
  margin-top: 22px;
  font-family: 'MarvinVisions';
  color: #5296FF;
}

.close {
  position: absolute;
  top: 30px;
  right: 10px;
  background-color: white;
  border: none;
  padding: 5px;
}

.close span {
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
}

.benefitsSection .container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.benefitsSection .card {
  width: 200px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #090907 !important;
  color: #5296FF;
}

.diarioBienvenida {
  max-width: 50%;
  max-height: 50%;
}

@font-face {
  font-family: 'MarvinVisions';
  src: url('../../assets/tipografias/MarvinVisions-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'AcuminPro';
  src: url('../../assets/tipografias/Acumin-RPro.otf');
}

.carousel-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-image {
  max-width: 50%;
  height: auto;
}

.modal-fullscreen {
  max-width: 100%;
  height: 100%;
  margin: 0;
}

.modal-content {
  height: 100%;
}

.diarioBienvenida {
  max-width: 100%;
  max-height: 80%;
}

/* Custom styles for the AssessmentPlan component */
.accordion h3 {
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
}

.accordion .btn-link {
  font-size: 0.875rem;
  text-align: left; 
  width: 100%; 
}


.accordion .card-body {
  font-size: 0.875rem;
  text-align: left;
}

h2.text-center {
  text-align: center;
}
